import axios from '@axios'

export default {
  namespaced: true,
  state: {
    projects: [],
    currentProject: [],
    templates: [],
  },
  getters: {
    getListProjects: state => state.projects,
    getCurrentProject: state => state.currentProject,
    getTemplates: state => state.templates,
  },
  mutations: {
    setListProjects: (state, projects) => {
      state.projects = projects
    },
    setCurrentProject: (state, projects) => {
      state.currentProject = projects
    },
    setTemplates: (state, templates) => {
      state.templates = templates
    },
  },
  actions: {
    fetchProjects(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/project', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProject(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`project/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateProject(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`project/${data.id}`, data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error.response)
            reject(error)
          })
      })
    },
    paginateProject(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/project', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateMatch(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`match/${data.id}`, data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error.response)
            reject(error)
          })
      })
    },
    fetchClasses(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/class', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClass(ctx, id) {
      return axios.get(`class/${id}`)
    },
    updateClass(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`class/${data.id}`, data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error.response)
            reject(error)
          })
      })
    },
    fetchUsers(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadReport(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('export', data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error.response)
            reject(error)
          })
      })
    },
    fetchTemplates(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('template', { params: payload })
          .then(response => {
            console.log(response)
            ctx.commit('setTemplates', response.data.data.templates)
            resolve(response)
          })
          .catch(error => {
            console.log(error.response)
            reject(error)
          })
      })
    },
    sendEmail(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('send-email', data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error.response)
            reject(error)
          })
      })
    },
  },
}
