export default [
  // *===============================================---*
  // *--------- Rule  ---------------------------------------*
  // *===============================================---*
  {
    path: '/rule',
    name: 'rule.list',
    component: () => import('@/views/rule/Rule.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      resource: 'Auth',
    },
  },
]
