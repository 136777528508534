import axios from '@axios'

export default {
  namespaced: true,
  state: {
    rules: [],
  },
  getters: {
    getListRules: state => state.rules,
  },
  mutations: {
    setListRules: (state, rules) => {
      state.rules = rules
    },
  },
  actions: {
    fetchRules(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('rule', { params: queryParams })
          .then(response => {
            ctx.commit('setListRules', response.data.data.rules)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchRule(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`rule/${id}`)
          .then(response => {
            // ctx.commit('setCurrentRule', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addRule(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('rule', data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateRule(ctx, data) {
      data.append('_method', 'put')

      return new Promise((resolve, reject) => {
        axios
          .post(`rule/${data.get('id')}`, data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error.response)
            reject(error)
          })
      })
    },
    deleteRule(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`rule/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    forceDeleteRule(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`rule/${id}`, { params: { definitive_delete: true } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
