export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      resource: 'Auth',
    },
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
]
