export default [
  // *===============================================---*
  // *--------- Project  ---------------------------------------*
  // *===============================================---*
  {
    path: '/progetti',
    name: 'progetti-list',
    component: () => import('@/views/project/Project.vue'),
    meta: {
      contentRenderer: 'ecommerce-application',
      contentClass: 'email-application',
    },
  },
  {
    path: '/progetto/:progetto',
    name: 'progetto-show',
    component: () => import('@/views/project/Project-show.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  {
    path: '/progetto/:progetto/:folder',
    name: 'progetto-show-folder',
    component: () => import('@/views/project/Project-show.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'progetto-show',
    },
    beforeEnter(to, _, next) {
      if (['classi', 'matches', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
]
