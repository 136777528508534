export default [
  // *===============================================---*
  // *--------- File  ---------------------------------------*
  // *===============================================---*
  {
    path: '/training',
    name: 'training.list',
    component: () => import('@/views/file/FileTraining.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      resource: 'Auth',
      type: 'training',
    },
  },
  {
    path: '/pressroom',
    name: 'pressroom.list',
    component: () => import('@/views/file/FilePressroom.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      resource: 'Auth',
      type: 'pressroom',
    },
  },
]
