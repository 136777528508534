export default [
  // *===============================================---*
  // *--------- Quiz  ---------------------------------------*
  // *===============================================---*
  {
    path: '/quiz',
    name: 'quiz.list',
    component: () => import('@/views/quiz/Quiz.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      resource: 'Auth',
    },
  },
]
