import axios from '@axios'

export default {
  namespaced: true,
  state: {
    quiz: [],
    groupQuiz: [],
  },
  getters: {
    getListQuiz: state => state.quiz,
    getListGroupQuiz: state => state.groupQuiz,
  },
  mutations: {
    setListQuiz: (state, quiz) => {
      state.quiz = quiz
    },
    setListGroupQuiz: (state, groupQuiz) => {
      state.groupQuiz = groupQuiz
    },
  },
  actions: {
    fetchGroupQuizzes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('quiz', { params: queryParams })
          .then(response => {
            ctx.commit('setListGroupQuiz', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchQuizzes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('quiz', { params: queryParams })
          .then(response => {
            ctx.commit('setListQuiz', response.data.data.quiz)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchQuiz(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`quiz/${id}`)
          .then(response => {
            // ctx.commit('setCurrentQuiz', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addGroupQuiz(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('quiz', data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addQuiz(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('quiz', data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateQuiz(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`quiz/${data.id}`, data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error.response)
            reject(error)
          })
      })
    },
    updateGroupQuiz(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`quiz/${data.id}`, data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error.response)
            reject(error)
          })
      })
    },
    deleteGroupQuiz(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`quiz/${id}`, { params: { group_quiz_id: id } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteQuiz(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`quiz/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    forceDeleteQuiz(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`quiz/${id}`, { params: { definitive_delete: true } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
