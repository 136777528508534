import axios from '@axios'

export default {
  namespaced: true,
  state: {
    file: [],
    groupFile: [],
  },
  getters: {
    getListFile: state => state.file,
    getListGroupFile: state => state.groupFile,
  },
  mutations: {
    setListFile: (state, file) => {
      state.file = file
    },
    setListGroupFile: (state, groupFile) => {
      state.groupFile = groupFile
    },
  },
  actions: {
    fetchFiles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('file', { params: queryParams })
          .then(response => {
            ctx.commit('setListFile', response.data.data.files)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchGroupFiles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('file', { params: queryParams })
          .then(response => {
            ctx.commit('setListGroupFile', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchFile(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`file/${id}`)
          .then(response => {
            // ctx.commit('setCurrentQuiz', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addGroupFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('file', data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('file', data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateFile(ctx, data) {
      data.append('_method', 'put')
      return new Promise((resolve, reject) => {
        axios
          .post(`file/${data.get('id')}`, data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error.response)
            reject(error)
          })
      })
    },
    deleteFile(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`file/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    forceDeleteFile(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`file/${id}`, { params: { definitive_delete: true } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
