export default [
  {
    path: '/staff',
    name: 'staff-list',
    meta: {
      resource: 'Staff',
      action: 'index',
    },
    component: () => import('@/views/staff/staff-list/Staff.vue'),
  },
]
