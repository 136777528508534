import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import QuizzesStoreModule from '@/views/quiz/QuizzesStoreModule'
import userStoreModule from '@/views/staff/userStoreModule'
import projectStoreModule from '@/views/project/projectStoreModule'
import RulesStoreModule from '@/views/rule/RulesStoreModule'
import statisticsStoreModule from '@/views/dashboard/statisticsStoreModule'
import FileStoreModule from '@/views/file/FileStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    quizzes: QuizzesStoreModule,
    staff: userStoreModule,
    files: FileStoreModule,
    project: projectStoreModule,
    rules: RulesStoreModule,
    statistics: statisticsStoreModule,
  },
  strict: process.env.DEV,
})
